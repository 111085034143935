import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Margin } from '../../components/margin';
import { PopUpError } from '../../components/popups';
import { Select } from '../../components/select';
import HandshakeApi from '../../api/handshakeApi';
import Logo from '../../assets/icons/mdi-Catalina-full.svg';
import HeaderLogo from '../../assets/icons/mdi-logo-caja-arequipa-blue.svg';
import styles from './login.module.css';

export const Login = () => {
  const [open, setOpen] = useState(false);
  const [terms, setTerm] = useState(false);
  const [disable, setDisable] = useState(true);
  const [accessCode, setAccessCode] = useState('');
  const [documentType, setDocumentType] = useState('');
  const navigate = useNavigate();

  const documentTypes = [
    { label: 'Carnet de extranjería', value: 'Carnet de extranjería' },
    { label: 'DNI', value: 'DNI' },
  ];

  useEffect(() => {
    if (accessCode.length > 0 && documentType.length > 0 && terms) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [accessCode, documentType, terms]);

  const validateAccessCode = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)
      && inputValue?.length > 0
      && /^[0-9]*$/.test(inputValue)
    ) {
      setAccessCode(inputValue);
    } else {
      setAccessCode('');
    }
  };

  const handleContinue = async () => {
    const data = {
      numberDocument: accessCode,
      typeDocument: 'CC',
      terms: false,
      description: '',
    };
    if (!accessCode || disable || !terms) return;
    setDisable(true);
    await HandshakeApi.validateDNI(data)
      .then(async (res) => {
        if (res?.url === '/') {
          localStorage.removeItem('isLogged');
          setOpen(true);
        } else {
          localStorage.setItem('isLogged', true);
          navigate('/home');
        };
      })
      .catch((err) => {
        localStorage.removeItem('isLogged');
        setOpen(true);
        setDisable(false);
        // eslint-disable-next-line no-console
        console.error('ERROR: ', err);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles['logo-header']} src={HeaderLogo} alt={'header'} />
      </div>
      <Margin bottom={0.8} />
      <div className={styles.content}>
        <img className={styles.logo} src={Logo} alt={'logo'} />
        <Margin bottom={2.1} />
        <div className={styles.title}>
          <strong>¡Bienvenido(a) a nuestra<br />central de ayuda!</strong>
        </div>
        <Margin bottom={1.6} />
        <div className={styles.subtitle}>
          Por favor, ingresa tus datos para continuar
        </div>
        <Margin bottom={2.4} />
        <div className={styles['form-container']}>
          <p className={styles['form-label']}>
            <strong>Tipo de documento</strong>
          </p>
          <Select
            options={documentTypes} value={documentType}
            onChange={(e) => setDocumentType(e)} />
          <div></div>
          <p className={styles['form-label']}>
            <strong>Número de documento</strong>
          </p>
          <input
            defaultValue={accessCode}
            className={`${styles['form-input']}`}
            placeholder={'Escribir número'}
            style={accessCode?.length > 0
              ? { caretColor: '#01C5C6' }
              : {}
            }
            onChange={validateAccessCode}
            onKeyPress={(e) => {
              if (!/\d/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </div>
        <Margin bottom={1.6} />
        <div className={styles['terms-container']}>
          <input
            type={'checkbox'}
            value={terms}
            className={styles['terms-checkbox']}
            onChange={() => setTerm(!terms)}
            aria-label={'terms-checkbox'} />
          <p className={styles['terms-paragraph']}>
            Acepto los <span className={styles['terms-label']}>términos y condiciones</span> de la
            política de protección de datos.</p>
        </div>
        <Margin bottom={3.2} />
        <div className={styles['button-container']}>
          <button
            className={`${styles['continue-button']} ${!disable ? styles.send : styles.disabled}`}
            onClick={handleContinue}
            style={disable ? {} : {}}>
            Continuar
          </button>
        </div>
      </div>
      {open && <PopUpError open={open} setOpen={setOpen} />}
    </div>
  );
};
