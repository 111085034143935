import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from '../../assets/icons/mdi-expand-more.svg';
import styles from './select.module.css';

export const Select = ({
  defaultValue = '',
  label = '',
  onChange,
  options = [],
  placeholder = 'Selecciona una opción',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(placeholder);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);
  };

  const inputStyle
    = `${selectedOption.includes('Selecciona')
      ? styles.placeholder
      : ''} ${isOpen ? styles.focused : ''}`;

  return (
    <div className={styles['select-container']} {...props}>
      {label && <p>{label}</p>}
      <div
        className={`${styles['select-input']} ${inputStyle}`}
        onClick={() => setIsOpen(!isOpen)}>
        {selectedOption}
        <img className={styles.icon} src={ArrowIcon} alt={'arrow'} />
      </div>
      {isOpen && (
        <div className={styles['select-options']}>
          {options.map((item, index) => (
            <div key={index} onClick={() => handleOptionClick(item.value)}>
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
};
