import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Margin } from '../../components/margin';
import { Button } from '../../components/buttons';
import AvatarIcon from '../../assets/icons/mdi-catalina-avatar.svg';
import CloseIcon from '../../assets/icons/mdi-close-white.svg';
import MicIcon from '../../assets/icons/mdi-mic-white.svg';
import MinimizeIcon from '../../assets/icons/mdi-minimize-white.svg';
import SearchIcon from '../../assets/icons/mdi-search.svg';
import styles from './home.module.css';

export const Home = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>¿Necesitas ayuda?</p>
        <div className={styles.actions}>
          <img src={MinimizeIcon} alt={'minimize'} className={styles.icons} />
          <img src={CloseIcon} alt={'close'} className={styles.icons} />
        </div>
      </div>
      <Margin bottom={3.2} />
      <div className={styles['search-container']}>
        <div className={styles.search}>
          <img src={SearchIcon} alt={'search'} className={styles.icons} />
        </div>
        <input
          className={`${styles['form-input']}`}
          placeholder={'Ingresa aquí tu consulta'}
          onKeyPress={(e) => {
            if (!/\d/.test(e.key)) {
              e.preventDefault();
            }
          }}
        />
        <div className={styles.microphone}>
          <img src={MicIcon} alt={'close'} className={styles.icons} />
        </div>
      </div>
      <Margin bottom={3.2} />
      <div className={styles.content}>
        <img src={AvatarIcon} alt={'avatar'} className={styles.avatar} />
        <Margin bottom={3.45} />
        <p className={styles.description}>
          <strong>¡Hola! Soy Catalina</strong><br />
          Realiza tu consulta a través del buscador o selecciona alguna de las
          opciones que te ofrecemos en los botones.
        </p>
        <Margin bottom={8} />
        <div className={styles.buttons}>
          <Button color={'skyblue'} style={{ width: '263px', height: '36px' }}>
            Bloqueos
          </Button>
          <Button color={'skyblue'} style={{ width: '263px', height: '36px' }}>
            Solicitud de créditos
          </Button>
          <Button color={'skyblue'} style={{ width: '263px', height: '36px' }}>
            Sugerencias
          </Button>
          <Button
            color={'navy'} style={{ width: '263px', height: '36px' }}
            onClick={() => navigate('/chat')}>
            Chatea conmigo
          </Button>
        </div>
      </div>
    </div>
  );
};
