import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sleep } from '../../context/message-context';
import { ChatContext } from '../../context/chat-context';
import { PopupContext } from '../../context/popup-context';
import { Margin } from '../margin';
import ChatApi from '../../api/chatApi';
import styles from './nps.module.css';

export const Nps = ({ openNps, setOpenNps }) => {
  const { attr, meth } = useContext(ChatContext);
  const { idSession, sessionTimeOut, socket } = attr;
  const { connectSocket, disconnectSocket, clearInput } = meth;
  const { popupMeth } = useContext(PopupContext);
  const { setOpenInactive } = popupMeth;
  const [showFeedback, setShowFeedback] = useState(false);
  const [cleanTimeOut, setCleanTimeOut] = useState(false);

  const { REACT_APP_DIALOG_BOTSMART } = process.env;

  useEffect(() => {
    let npsTimer;
    if (sessionTimeOut) {
      npsTimer = setTimeout(async () => {
        setOpenInactive(false);
        await handleQualification('no-response-timeout');
        localStorage.removeItem('isLogged');
        await sleep(50);
        window.location.href = '/';
      }, 15000);
    }
    if (cleanTimeOut) {
      clearTimeout(npsTimer);
    }
    return () => clearTimeout(npsTimer);
    // eslint-disable-next-line
  }, [sessionTimeOut, cleanTimeOut]);

  const closePopUp = async () => {
    setShowFeedback(true);
    await sleep(3000);
    setShowFeedback(false);
    setOpenNps(false);
    if (socket) disconnectSocket();
  };

  const handleQualification = async (answer) => {
    const data = {
      answer,
      dialogId: REACT_APP_DIALOG_BOTSMART,
      idQuestion: 'mhvXdrZT4jP5T8vBxuvm75',
      integration_id: 'hE5T9OjxTNfpPYsICkUi',
      question: '¿Le resulto útil la información brindada por Catalina?',
      type: 'text',
      id_session: idSession,
    };

    if (!answer) return;
    await ChatApi.sendNPS(data)
      .then(async () => {
      })
      .catch(async (err) => {
        // eslint-disable-next-line no-console
        console.error('ERROR: ', err);
      })
      .finally(() => {
        setCleanTimeOut(true);
        clearInput();
        closePopUp();
        connectSocket();
      });
  };

  if (!openNps) return <></>;

  if (showFeedback) return <NpsFeedback />;

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <p className={styles.title}>
          ¡Su opinión es muy importante!
        </p>
        <Margin bottom={2.4} />
        <p className={styles.subtitle}>
          ¿Le resulto útil la información brindada por <strong>Catalina</strong>?
        </p>
        <Margin bottom={3.2} />
        <div className={styles['thumbs-container']}>
          <div className={styles['thumbs-up']} onClick={() => handleQualification('yes')}>
            <p className={`${styles.qualification} ${styles.yes}`}>Sí</p>
          </div>
          <div className={styles['thumbs-down']} onClick={() => handleQualification('no')}>
            <p className={`${styles.qualification} ${styles.no}`}>No</p>
          </div>
        </div>
        <Margin bottom={1.2} />
      </div>
    </div>
  );
};

Nps.propTypes = {
  openNps: PropTypes.bool,
  setOpenNps: PropTypes.func,
};

export const NpsFeedback = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <p className={styles.title}>
          ¡Gracias por su opinión!
        </p>
        <Margin bottom={2.4} />
        <p className={styles.subtitle}>
          Con ella nos ayuda a mejorar cada día.
        </p>
        <Margin bottom={3.2} />
        <div className={styles['check-icon']} />
      </div>
    </div>
  );
};

