import React, { useRef, useContext, useEffect } from 'react';
import { ChatContext } from '../../context/chat-context';
import { MessagesContext } from '../../context/message-context';
import { Chip, Slide } from '@mui/material';
import { RenderMessage } from '../render-message';
import ChatUtils from '../../utils/chat-utils';
import './messages.css';

export const Messages = () => {
  const { attr } = useContext(ChatContext);
  const { response, loadingMessage, networkStatus } = attr;
  const { messageAttr } = useContext(MessagesContext);
  const { loadRecord } = messageAttr;
  const messagesEndRef = useRef(null);
  const color = '#01C5C6';

  const renderDate = (date) => {
    return (
      <small style={{ color: '#C1C1C1', fontSize: '10px', lineHeight: '14px' }}>
        {ChatUtils.formatAMPM(new Date(date))}
      </small>
    );
  };

  const scrollToBottom = () => {
    try {
      const lastMessage
        = response.length > 0 ? response[response.length - 1] : null;
      if (lastMessage && response.length !== 1) {
        setTimeout(
          () => {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
          },
          lastMessage.from === 1 ? 50 : 1
        );
      }
    } catch (e) { }
  };

  useEffect(scrollToBottom, [response]);

  const getMessageClassName = (from) => {
    const classes = ['message'];
    classes.push(from === 0 ? 'right' : 'left');
    return classes
      .filter((it) => it?.length > 0)
      .join(' ')
      .trim();
  };

  const bubbleStyle = (from) => {
    const primaryColor = !color ? 'var(--mdc-theme-primary)' : color;
    const bubbleValidation = from === 0 ? `${primaryColor}` : '';
    return { '--bubble-color': bubbleValidation };
  };

  return (
    <>
      {networkStatus.show && (
        <div className={'network-container'}>
          <Slide
            direction={'down'}
            in={networkStatus.show}
            mountOnEnter
            unmountOnExit>
            <Chip
              sx={{
                height: 'auto',
                '& .MuiChip-label': {
                  display: 'block',
                  whiteSpace: 'normal',
                },
              }}
              icon={networkStatus.icon}
              label={networkStatus.message} />
          </Slide>
        </div>
      )}
      <div className={'messages'}>
        {(response || []).map((it) => {
          if (!it) return <></>;
          return (
            <>
              {it?.responseType !== 'survey' && (
                <div
                  key={`${it?.from}-${it?.message}`}
                  className={`container-message ${it?.from === 0 ? 'end' : 'start'}`}>
                  <div
                    className={`container-bubbles ${it?.from === 0 ? 'end' : 'start'}`}>
                    <div
                      className={getMessageClassName(it?.from)}
                      key={`message-class-${it?.from === 0}-${it}`}
                      style={bubbleStyle(it?.from)}>
                      <RenderMessage message={it} />
                    </div>
                    <p className={'date-container'}>{renderDate(it?.time)}</p>
                  </div>
                </div>
              )}
            </>
          );
        })}
        {loadingMessage && (
          <div className={'container-message start'}>
            <div className={'container-bubbles start'}>
              <div className={'message left'} style={{ '--bubble-color': '' }}>
                <div className={'loader-container'}>
                  <div className={'loader'}></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {loadRecord && (
          <div className={'container-message end'}>
            <div className={'container-bubbles end'}>
              <div
                className={'message right'} style={{ '--bubble-color': '#01C5C6' }}>
                <div className={'spinner'}></div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
    </>
  );
};
