import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const WavesVisualizer = ({ stream }) => {
  const canvasRef = useRef(null);
  let animationFrameId = null;
  const bars = [];
  let counter = 0;
  let audioContext = null;
  let analyser = null;

  // Genera los datos de las barras según el volumen del audio
  const generateBarData = (canvasWidth, canvasHeight) => {
    const minBarHeight = 1;
    const maxBarHeight = 41;
    const sensitivity = 0.5; // Sensibilidad al sonido (ajustable)

    // Obtener el volumen del stream del micrófono cada 10 barras
    if (counter % 5 === 0) {
      const audioData = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(audioData);
      const volume = audioData.reduce((sum, value) => sum + value, 0) / audioData.length;

      // Ajustar el tamaño de la barra según el volumen
      const barWidth
        = volume <= 5
          ? 10
          : Math.pow((volume * 5) / 255, sensitivity)
          * (maxBarHeight - minBarHeight) + minBarHeight;

      const barPositionX = canvasWidth;
      const barPositionY = canvasHeight / 2;

      // Añadir la nueva barra al array de barras
      bars.push({
        width: barWidth,
        positionX: barPositionX,
        positionY: barPositionY,
      });
    }
  };

  // Renderiza las barras en el canvas
  const renderBars = (ctx, canvasWidth, canvasHeight) => {
    const barSpacing = 3.5;
    const animationSpeed = 2; // Velocidad de desplazamiento de las barras

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    ctx.fillStyle = '#4A586E'; // Color de las barras
    ctx.strokeStyle = '#FFFFFF80'; // Color del borde de las barras
    ctx.lineWidth = 3; // Grosor del borde de las barras
    ctx.lineCap = 'round'; // Estilo de los extremos del borde de las barras

    // Dibujar las barras
    bars.forEach((bar) => {
      ctx.fillRect(bar.positionX, bar.positionY - bar.width / 2, barSpacing, bar.width);
      ctx.strokeRect(bar.positionX, bar.positionY - bar.width / 2, barSpacing, bar.width);

      // Actualizar la posición de la barra
      bar.positionX -= animationSpeed;

      // Eliminar las barras que ya no están visibles en el canvas
      if (bar.positionX + barSpacing <= 0) {
        bars.shift();
      }
    });
  };

  // Genera las barras en base al volumen del audio
  const generateBars = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      generateBarData(canvasWidth, canvasHeight);
      renderBars(ctx, canvasWidth, canvasHeight);

      counter++;

      // Volver a dibujar las barras en la siguiente animación
      animationFrameId = requestAnimationFrame(generateBars);
    }
  };

  // Inicia la visualización de audio utilizando el stream del micrófono recibido
  const startAudioVisualization = () => {
    if (stream) {
      if (typeof window !== 'undefined') {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
      }
      analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      animationFrameId = requestAnimationFrame(generateBars);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const resizeCanvas = () => {
        const containerWidth = canvas.parentElement.offsetWidth;
        const containerHeight = canvas.parentElement.offsetHeight;
        canvas.width = containerWidth;
        canvas.height = containerHeight;
      };

      resizeCanvas();
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', resizeCanvas);
      }

      startAudioVisualization();

      return () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
        if (audioContext) {
          audioContext.close();
        }
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', resizeCanvas);
        }
      };
    }
    return () => { };
    // eslint-disable-next-line
  }, [stream]);

  return (
    <div
      style={{
        position: 'absolute',
        marginLeft: '24px',
        height: '24px',
      }}>
      <canvas width={'231px'} height={'24px'} ref={canvasRef} />
    </div>
  );
};

WavesVisualizer.propTypes = {
  stream: PropTypes.object,
};

export default WavesVisualizer;
