/* eslint-disable max-lines */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from '../../context/chat-context';
import { MessagesContext, sleep } from '../../context/message-context';
import ChatApi from '../../api/chatApi';
import useSafariIOSDetection from '../../hooks/useIosDevice';
import styles from './recorder.module.css';
import './recorder.scss';

export const MicHandler = ({
  display,
  handleTouchStart,
  handleTouchEnd,
  handleClickSafari,
  className,
}) => {
  const { isSafari, isiOS } = useSafariIOSDetection();

  return (
    <>
      {!isSafari && !isiOS && (
        <>
          <div
            style={{ display }}
            onClick={handleTouchStart}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            id={'recorder-button'}
            className={className}
          />
        </>
      )}
      {(isSafari || isiOS) && (
        <div
          style={{ display }}
          onClick={handleClickSafari}
          id={'recorder-button'}
          className={className}
        ></div>
      )}
    </>
  );
};

MicHandler.propTypes = {
  display: PropTypes.string,
  handleTouchStart: PropTypes.func,
  handleTouchEnd: PropTypes.func,
  handleClickSafari: PropTypes.func,
  className: PropTypes.string,
};

const AudioRecorder = ({ disabled = true }) => {
  const { config, token, infConversation, meth } = useContext(ChatContext);
  const { dispatchChatData, sendMessage } = meth;
  const { dialogId, provider } = config;
  const { messageAttr, messageMeth } = useContext(MessagesContext);
  const { stream, recording } = messageAttr;
  const { setStream, setRecording, setLoadRecord } = messageMeth;
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [iconChange, setIconChange] = useState(false);
  const [micAuth, setAuthMic] = useState(true);
  const [render, setRender] = useState(true);
  const [check, setCheck] = useState(true);
  const { isSafari, isiOS } = useSafariIOSDetection();

  let recorder;

  const startRecording = () => {
    setIconChange(true);
    setRecording(true);
    if (!dialogId || dialogId.length <= 0) return;
    if (!provider || provider.length <= 0) return;
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const onStop = async (recordedBlob) => {
    setRecording(false);
    setIconChange(false);
    setLoadRecord(true);
    const { advisor } = infConversation;
    const bufferAudio = Buffer.from(
      new Uint8Array(await recordedBlob.arrayBuffer())
    );
    if (!advisor && advisor !== true) {
      await ChatApi.convertToText(bufferAudio, token)
        .then(async (response) => {
          const { transcript } = response || response?.data;
          if (transcript !== '') {
            dispatchChatData({
              type: 'new',
              payload: [{
                type: 'watson-response',
                response: [
                  {
                    message: transcript,
                  },
                ],
                from: 0,
                time: new Date(),
              }],
            });
            await sendMessage({
              message: transcript,
              to_user: '',
              type: 'text',
              typeUser: false,
              formatQuestion: 'audio',
            });
            await sleep(100);
          }
          setLoadRecord(false);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
          setLoadRecord(false);
        });
    }
  };

  const checkMicrophonePermission = async () => {
    setCheck(false);
    await sleep(200);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async (stream) => {
        ChatApi.sendEvents({
          eventType: 'mic-true',
          description: 'mic-true',
          device: isSafari || isiOS ? 'ios' : 'android',
          option: 'events',
        });
        setCheck(true);
        setStream(stream);
        recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        setAuthMic(true);
        try {
          const chunks = [];
          recorder.ondataavailable = (e) => chunks.push(e.data);
          recorder.onstop = () => {
            const recordedBlob = new Blob(chunks, { type: 'audio/webm' });
            onStop(recordedBlob);
          };
          if (recorder?.state === 'inactive') {
            recorder.start();
            startRecording();
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        ChatApi.sendEvents({
          eventType: 'mic-false',
          description: 'mic-false',
          device: isSafari || isiOS ? 'ios' : 'android',
          option: 'events',
        });
        setCheck(true);
        setAuthMic(true);
      });
  };

  const refreshButton = () => {
    setRender(false);
    setTimeout(() => {
      setRender(true);
    }, 1000);
  };

  const handleTouchStart = async (e) => {
    refreshButton();
    if (!recording) {
      checkMicrophonePermission();
    } else {
      stopRecording();
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        setStream(null);
      }
    }
  };

  const handleTouchEnd = (e) => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
    if (stream) {
      mediaRecorder.stop();
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
    stopRecording();
  };

  const handleClickSafari = (e) => {
    e.preventDefault();
    refreshButton();
    if (!recording) {
      checkMicrophonePermission();
    } else {
      stopRecording();
      if (mediaRecorder && isRecording) {
        mediaRecorder.stop();
        setIsRecording(false);
      }
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        setStream(null);
      }
    }
  };

  const display = !check || !render ? 'none' : '';

  const handlerProps = {
    display,
    handleTouchStart,
    handleTouchEnd,
    handleClickSafari,
  };

  return (
    <>
      <MicHandler {...handlerProps} className={styles['mic-handler']} />
      {micAuth && (
        <button
          disabled={disabled}
          className={`recording-button ${iconChange ? 'pulse' : ''}`}>
        </button>
      )}
    </>
  );
};

AudioRecorder.propTypes = {
  disabled: PropTypes.bool,
  dialogId: PropTypes.string,
  provider: PropTypes.string,
  step: PropTypes.number,
  setFirstMesage: PropTypes.func,
  token: PropTypes.string,
  infConversation: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  reverse: PropTypes.bool,
  endComunication: PropTypes.bool,
};

export default AudioRecorder;
