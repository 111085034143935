import React, { useContext } from 'react';
import { PopupContext } from '../../context/popup-context';
import { Nps } from '../../components/nps';
import { Card } from '@rmwc/card';
import { Input } from '../../components/input';
import { Navbar } from '../../components/navbar';
import { Messages } from '../../components/messages';
import { ExpiredCampaigns } from '../../components/expired-campaign';
import { PopUpConfirm, PopUpInactive, PopUpLogout } from '../../components/popups';
import { Container } from '../../components/app-container';
import styles from './chat.module.css';

export const Chat = () => {
  const { popupAttr, popupMeth } = useContext(PopupContext);
  const { openNps, openLogout, openConfirm, openExpired, openInactive } = popupAttr;
  const { setOpenNps, setOpenLogout, setOpenConfirm, setOpenExpired, setOpenInactive } = popupMeth;

  const isLogged = localStorage.getItem('isLogged');

  if (!isLogged) return <></>;

  return (
    <Container>
      <Card className={'chat-box'}>
        <div className={styles['top-container']}>
          <Navbar />
          <ExpiredCampaigns open={openExpired} setOpen={setOpenExpired} />
          <Messages />
        </div>
        <div style={{ bottom: 0, position: 'fixed' }}>
          <Input />
        </div>
      </Card>
      <Nps openNps={openNps} setOpenNps={setOpenNps} />
      <PopUpInactive open={openInactive} setOpen={setOpenInactive} setOpenNps={setOpenNps} />
      <PopUpConfirm open={openConfirm} setOpen={setOpenConfirm} setOpenNps={setOpenNps} />
      <PopUpLogout open={openLogout} setOpen={setOpenLogout} />
    </Container>
  );
};
