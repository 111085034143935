export const formatText = (text, shouldReplaceInMatch) => {
  let newDesc = text?.replace(/\n/gmu, '<br/>')
    .replaceAll('**', '*+')
    .replaceAll('“**', '---')
    .replaceAll(/\*{2}”/gmu, '---')
    .replaceAll('¨', '-_-')
    .replaceAll(/¨/gmu, '-_-');
  const matchBold = text?.match(/\*([\s\p{L}!,'"#$%&()¡¿+]+)\*/gmu);
  const matchPDF = text?.match(/https?:\/\/[^\s]+\.pdf|www\.[^\s]+\.pdf/gmi);
  let matchLink = text?.match(/https?:\/\/[^\s]+|www\.[^\s]+/gmu);
  const matchItalic = text?.match(/~([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)~/gmu);
  const matchRed = newDesc?.match(/-_-([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)-_-/gmu);
  const matchBoldRed = newDesc?.match(/\*\+([\w\s\p{L}!.,'"#$%&/()¡?¿+[\]]+)\*\+/gmu);
  const matchSpecialCaseBold = text?.match(/\*“([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)”\*/gmu);
  const matchSpecialCaseBoldRed = newDesc?.match(/-{3}([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)-{3}/gmu);
  const matchUnderScore = text?.match(/_([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)_/gmu);
  const matchScript = text?.match(/--([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)--/gmu);
  const matchTittle = text?.match(/#([\w\s\p{L}!"#$%&()¡?¿+[\]]+)#/gmu);
  if (matchBoldRed) {
    matchBoldRed.forEach((element) => {
      newDesc = newDesc.replace(element,
        `<strong style="color:#DD141D;">${element.substring(2, element.length - 2)}</strong>`);
    });
  }
  if (matchRed) {
    matchRed.forEach((element) => {
      newDesc = newDesc.replace(element,
        `"<span style="color:#DD141D;">${element.substring(3, element.length - 3)}</span>"`);
    });
  }
  if (matchSpecialCaseBold) {
    matchSpecialCaseBold.forEach((element) => {
      newDesc = newDesc.replace(element,
        `"<strong style="color:#000000">${element.substring(2, element.length - 2)}</strong>"`);
    });
  }
  if (matchSpecialCaseBoldRed) {
    matchSpecialCaseBoldRed.forEach((element) => {
      newDesc = newDesc.replace(element,
        `"<strong style="color:#DD141D;">${element.substring(3, element.length - 3)}</strong>"`);
    });
  }
  if (matchBold) {
    matchBold.forEach((element) => {
      newDesc = newDesc.replace(element,
        `<strong style="color:#000000">${element.substring(1, element.length - 1)}</strong>`);
    });
  }
  if (matchItalic) {
    matchItalic.forEach((element) => {
      newDesc = newDesc.replace(element, `<i>${element.substring(1, element.length - 1)}</i>`);
    });
  }
  if (matchPDF) {
    matchLink = '';
    const pdfTag = newDesc.replace(/https?:\/\/[^\s]+\.pdf|www\.[^\s]+\.pdf/gmi, (element) => {
      let url = element;
      if (!element.match(/https:\/\//)) {
        url = `https://${element}`;
      }
      url = url.replace(/_/g, '%5F');
      return `<a class="pdf-button" target="_blank" href="${url}">Ver documento</a>`;
    });
    newDesc = pdfTag;
  }
  if (matchLink) {
    const linkTag = newDesc.replace(/https?:\/\/[^\s]+|www\.[^\s]+/gmu, (element) => {
      let link = element;
      if (!element.match(/https:\/\//)) {
        link = `https://${element}`;
      }
      return `<a href="${link}" style="word-break: break-word; color: #000000;
        font-weight: bolder;" target="_blank">${element}</a>`;
    });
    newDesc = linkTag;
  }
  if (matchUnderScore && !matchLink) {
    if (shouldReplaceInMatch) {
      newDesc = newDesc.replace(/_/g, ' ');
    } else {
      matchUnderScore.forEach((element) => {
        newDesc = newDesc.replace(element,
          `<span style="text-decoration: underline">
            ${element.substring(1, element.length - 1)}
          </span>`);
      });
    }
  }
  if (matchTittle) {
    matchTittle.forEach((element) => {
      newDesc = newDesc.replace(element,
        `<h2 style="font-weight: 500;">
          ${element.substring(1, element.length - 1)}
        </h2>`);
    });
  }
  if (matchScript) {
    matchScript.forEach((element) => {
      newDesc = newDesc.replace(element,
        `<span style="text-decoration: line-through;">
          ${element.substring(2, element.length - 2)}
        </span>`);
    });
  }

  return ({ __html: newDesc });
};

export default formatText;
