/* eslint-disable max-lines */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { PopupContext } from './popup-context';
import { MessagesContext } from './message-context';
import {
  SignalWifiOffOutlined,
  SignalWifiStatusbar4BarOutlined,
  SignalWifiStatusbarConnectedNoInternet4Outlined
} from '@mui/icons-material';
import ChatApi from '../api/chatApi';
import io from 'socket.io-client';
import ChatUtils from '../utils/chat-utils';
import useSafariIOSDetection from '../hooks/useIosDevice';

const ChatContext = createContext('chat-context');

const ChatProvider = (props) => {
  const { children, config } = props;
  const { messageMeth } = useContext(MessagesContext);
  const { setHideBackArrow } = messageMeth;
  const { popupMeth } = useContext(PopupContext);
  const { setOpenInactive } = popupMeth;
  const [message, setMessage] = useState('');
  const [messageId, setMessageId] = useState('');
  const [step, setStep] = useState(0);
  const [question, setQuestion] = useState(0);
  const [socket, setSocket] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [idSession, setIdSession] = useState(null);
  const [response, setResponse] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [networkStatus, setNetworkStatus] = useState({});
  const [hideInput, setHideInput] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [loadRedirect, setLoadRedirect] = useState(false);
  const [finalMessage, setFinalMessage] = useState(false);
  const [openNpsPopUp, setOpenNpsPopUp] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [microphonePermissions, setMicrophonePermissions] = useState(false);
  const [chatData, dispatchChatData] = useReducer(ChatUtils.chatDataReducer, {
    messages: [],
    config,
  });

  const { isSafari, isiOS } = useSafariIOSDetection();

  const messages = (chatData.messages || []).flat();
  const newMessages = (chatData.new || []).flat();

  const {
    REACT_APP_CHAT,
    REACT_APP_DIALOG_BOTSMART,
  } = process.env;

  const getIdentificator = async () => {
    await ChatApi.getIdentificator()
      .then((response) => {
        setSessionId(response);
      })
      .catch((err) => {
        setSessionId(null);
        // eslint-disable-next-line no-console
        console.error('ERROR: ', err);
      });
  };

  useEffect(() => {
    if (!sessionId) {
      getIdentificator();
    } else {
      connectSocket();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  const connectSocket = () => {
    if (sessionId) {
      const socket = io(REACT_APP_CHAT, {
        transports: ['websocket'],
        path: '/socket',
        query: {
          ally: 'interbank',
          dialog_id: REACT_APP_DIALOG_BOTSMART,
          integration_id: 'hE5T9OjxTNfpPYsICkUi',
        },
      });
      setSocket(socket);
      return () => socket.close();
    }
    return socket;
  };

  const disconnectSocket = () => {
    setSessionId(null);
    return socket.close();
  };

  useEffect(() => {
    if (socket) {
      socket.on('user-history', (data) => {
        const messagesHistory = (data.chat || []).map((it) =>
          onEndpointResponse(JSON.parse(it))
        );
        setResponse(messagesHistory);
      });

      socket.on('connect', () => {
        setResponse([]);
        setHideBackArrow(true);
        checkMicrophone();
        setNetworkStatus({
          show: false,
          message: 'La red es estable.',
          icon: <SignalWifiStatusbar4BarOutlined />,
        });
      });

      socket.on('disconnect', () => {
        setNetworkStatus({
          show: true,
          message: 'La red es inestable.',
          icon: <SignalWifiStatusbarConnectedNoInternet4Outlined />,
        });
      });

      socket.on('connect_error', () => {
        setNetworkStatus({
          show: true,
          message:
            'No hay conexión a internet. Conéctate a internet e inténtalo de nuevo.',
          icon: <SignalWifiOffOutlined />,
        });
      });
    }
    // eslint-disable-next-line
  }, [socket, setSocket]);

  const checkMicrophone = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async (stream) => {
        setMicrophonePermissions(true);
        ChatApi.sendEvents({
          eventType: 'mic-true',
          description: 'mic-true',
          device: isSafari || isiOS ? 'ios' : 'android',
          option: 'events',
        });
      })
      .catch(() => {
        setMicrophonePermissions(false);
        ChatApi.sendEvents({
          eventType: 'mic-false',
          description: 'mic-false',
          device: isSafari || isiOS ? 'ios' : 'android',
          option: 'events',
        });
      });
  };

  const sendSurvey = async (question, answer, type, idQuestion) => {
    const data = { question, answer, type, idQuestion };
    const query = {
      integration_id: 'hE5T9OjxTNfpPYsICkUi',
      ally: 'interbank',
    };
    socket.emit('survey', data, query);
  };

  const sendMessage = ({ message, type, typeUser, formatQuestion, watsonMenu = false, title }) => {
    const data = {
      message,
      to_user: 'system',
      type,
      type_user: typeUser,
      finalMessage,
      format_question: formatQuestion,
      watsonMenu,
      title,
    };
    const query = {
      dialog_id: REACT_APP_DIALOG_BOTSMART,
      integration_id: 'hE5T9OjxTNfpPYsICkUi',
      ally: 'interbank',
    };
    socket.emit('message', data, query);
    setLoadingMessage(true);
    setMessage('');
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (socket) {
      socket.on('message', onEndpointResponse);
      return () => socket.off('message', onEndpointResponse);
    }
    // eslint-disable-next-line
  }, [socket, response]);

  const processNewMessages = () => {
    if (newMessages.length <= 0) return;
    for (const it of newMessages) {
      if (it?.type !== 'pause') {
        dispatchChatData({ type: 'add', payload: [{ ...it }] });
      }
    }
  };

  const redirectWattsonResponse = (it) => {
    if (!it?.response?.[0]?.text?.includes('interface-home')) {
      setResponse([
        ...response,
        {
          id: it.id,
          from: it.from,
          time: it.time,
          type: it.type,
          ally: it.ally,
          options: it.options,
          response: it.response,
          idSession: it.idSession,
          questionDer: it.questionDer,
          typeQuestion: it.typeQuestion,
          responseType: it.responseType,
        },
      ]);
    }
  };

  const messageIteration = (it) => {
    const { type = '' } = it;
    if (type === 'watson-response') {
      redirectWattsonResponse(it);
    }
  };

  useEffect(() => {
    messages.forEach(messageIteration);
    // eslint-disable-next-line
  }, [chatData?.messages]);

  const validateText = (text, it) => {
    if (text && Object.keys(text).includes('text')) {
      const { text: textArray = [] } = text;
      try {
        if (textArray.length > 1) text = textArray.join('\n');
        else text = textArray[0] || '';
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    return {
      ...it,
      text: text ? text.toString().trim() : undefined,
      date: Math.round(Date.now() / 1000),
    };
  };

  const chatDataDisp = (type, responses) => {
    dispatchChatData({
      type: 'new',
      payload:
        type !== 'options'
          ? responses
          : responses
            // eslint-disable-next-line
            .map((it) => {
              const { text } = it;
              validateText(text, it);
            })
            .filter((it) => {
              const isMessage = it.type === 'text';
              const validMessage = isMessage
                ? it.text && it.text.length > 0
                : true;
              return it.type !== 'pause' ? validMessage : true;
            }),
    });
  };

  const onEndpointResponse = (response) => {
    setLoadingMessage(false);
    const {
      ally,
      isCampaign = false,
      Nodo = '',
      optionList,
      type,
      typeQuestion,
      responseType: contextResponseType,
    } = response?.message?.data?.watsonContextVar || [];
    const {
      id,
      id_session: idSession,
      from_user: fromUser,
      date_time: dateTime,
      type: responseType,
      cleanSession,
    } = response || [];
    try {
      setMessageId(id);
      setIdSession(idSession);
      setOpenInactive(cleanSession);
      setSessionTimeOut(cleanSession);
      setOptionsList(optionList || []);
      const responseText
        = typeof response.message === 'object'
          ? response?.message?.data?.responses
          : response?.message;
      if (cleanSession) return;
      const responses = ChatUtils.typeMessages({
        id,
        Nodo,
        ally,
        fromUser,
        dateTime,
        isCampaign,
        optionList,
        responseText,
        responseType,
        typeQuestion,
        idSession,
        type: type || response?.type,
        contextResponseType,
      });
      chatDataDisp(type, responses);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('here in formater', e);
    }
  };

  useMemo(processNewMessages, [chatData?.new]);

  const clearInput = () => {
    const divElement = document.getElementById('w-input-text');
    if (divElement) {
      divElement.innerText = '';
      setMessage('');
    }
  };

  const attr = {
    campaignList,
    disableButton,
    finalMessage,
    hideInput,
    idSession,
    loadingMessage,
    loadRedirect,
    message,
    messageId,
    microphonePermissions,
    networkStatus,
    openNpsPopUp,
    openPopUp,
    optionsList,
    question,
    response,
    sessionTimeOut,
    showSurvey,
    socket,
    step,
  };

  const meth = {
    dispatchChatData,
    clearInput,
    connectSocket,
    disconnectSocket,
    sendMessage,
    sendSurvey,
    setCampaignList,
    setDisableButton,
    setFinalMessage,
    setHideInput,
    setLoadRedirect,
    setMessage,
    setMessageId,
    setOpenNpsPopUp,
    setOpenPopUp,
    setQuestion,
    setSessionId,
    setSessionTimeOut,
    setShowSurvey,
    setStep,
  };

  const contextValue = useMemo(
    () => ({ ...props, attr, meth }),
    // eslint-disable-next-line
    [attr, meth]
  );

  return (
    <ChatContext.Provider value={contextValue}>
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
  ally: PropTypes.string.isRequired,
  botName: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  infConversation: PropTypes.object,
  token: PropTypes.string.isRequired,
};

export { ChatContext };
export default ChatProvider;
