import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { sleep } from '../../context/message-context';
import { ChatContext } from '../../context/chat-context';
import { PopupContext } from '../../context/popup-context';
import { Button } from '../buttons';
import { Margin } from '../margin';
import styles from './popups.module.css';

export const PopUpError = ({ open, setOpen }) => {
  const [disabled, setDisabled] = useState(false);

  const handleContinue = async () => {
    setDisabled(true);
    await sleep(50);
    setOpen(false);
  };

  if (!open) return <></>;

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Margin top={4.8}/>
        <strong className={'red-bold'} style={{ marginBottom: '24px' }}>
          Ingreso inválido
        </strong>
        <div className={styles['popup-message']}>
          Su código de acceso es incorrecto, verifíquelo e intente nuevamente.
        </div>
        <Margin bottom={3.2}/>
        <Button disabled={disabled} color={'red'} onClick={handleContinue}>
          Continuar
        </Button>
        <Margin top={3}/>
      </div>
    </div>
  );
};

PopUpError.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export const PopUpConfirm = ({ open, setOpen, setOpenNps }) => {
  const { meth } = useContext(ChatContext);
  const { clearInput } = meth;
  const { popupMeth } = useContext(PopupContext);
  const { setOpenExpired } = popupMeth;
  const [disabledConfirm, setDisabledConfirm] = useState(false);
  const [disabledCancel, setDisabledCancel] = useState(false);

  const handleConfirm = async () => {
    setDisabledConfirm(true);
    setOpenExpired(false);
    clearInput();
    await sleep(50);
    setOpen(false);
    setOpenNps(true);
    setDisabledConfirm(false);
  };

  const handleCancel = async () => {
    setDisabledCancel(true);
    await sleep(50);
    setOpen(false);
    setDisabledCancel(false);
  };

  if (!open) return <></>;

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Margin top={4.8}/>
        <strong>
          Terminar consulta
        </strong>
        <Margin bottom={2.4}/>
        <div className={styles['popup-message']}>
          ¿Esta seguro que desea finalizar esta sesión de consulta e iniciar otra?
        </div>
        <Margin bottom={3.2}/>
        <Button disabled={disabledConfirm} color={'green'} onClick={handleConfirm}>
          Confirmar
        </Button>
        <Margin bottom={1.2}/>
        <Button outlined={true} disabled={disabledCancel} color={'green'} onClick={handleCancel}>
          Cancelar
        </Button>
        <Margin bottom={3}/>
      </div>
    </div>
  );
};

PopUpConfirm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenNps: PropTypes.func,
};

export const PopUpInactive = ({ open, setOpen, setOpenNps }) => {
  const { popupMeth } = useContext(PopupContext);
  const { setOpenExpired } = popupMeth;
  const [disabledConfirm, setDisabledConfirm] = useState(false);

  const handleConfirm = async () => {
    setDisabledConfirm(true);
    setOpenExpired(false);
    await sleep(50);
    setOpen(false);
    setOpenNps(true);
    setDisabledConfirm(false);
  };

  if (!open) return <></>;

  return (
    <div className={styles.container}>
      <div className={styles['inactive-card']}>
        <strong>
          Sesión expirada
        </strong>
        <Margin bottom={2.4}/>
        <div className={styles['popup-message']}>
          Su sesión de consulta se ha cerrado por inactividad
        </div>
        <Margin bottom={3.2}/>
        <Button disabled={disabledConfirm} color={'green'} onClick={handleConfirm}>
          Continuar
        </Button>
      </div>
    </div>
  );
};

PopUpInactive.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenNps: PropTypes.func,
};

export const PopUpLogout = ({ open, setOpen }) => {
  const { attr, meth } = useContext(ChatContext);
  const { socket } = attr;
  const { disconnectSocket } = meth;
  const [disabledConfirm, setDisabledConfirm] = useState(false);
  const [disabledCancel, setDisabledCancel] = useState(false);

  const handleConfirm = async () => {
    if (socket) disconnectSocket();
    setDisabledConfirm(true);
    localStorage.removeItem('isLogged');
    await sleep(50);
    window.location.href = '/';
  };

  const handleCancel = async () => {
    setDisabledCancel(true);
    await sleep(50);
    setOpen(false);
    setDisabledCancel(false);
  };

  if (!open) return <></>;

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Margin top={4.8}/>
        <strong>
          Salir de Catalina
        </strong>
        <Margin bottom={2.4}/>
        <div className={styles['popup-message']}>
          ¿Esta seguro que desea salir del Centro de Información y Ayuda?
        </div>
        <Margin bottom={3.2}/>
        <Button disabled={disabledConfirm} color={'green'} onClick={handleConfirm}>
          Confirmar
        </Button>
        <Margin bottom={1.2}/>
        <Button outlined={true} disabled={disabledCancel} color={'green'} onClick={handleCancel}>
          Cancelar
        </Button>
        <Margin bottom={3}/>
      </div>
    </div>
  );
};

PopUpLogout.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
