/* eslint-disable max-lines */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from '../../../context/chat-context';
import { Button } from '../../buttons';
import { Margin } from '../../margin';
import ChatApi from '../../../api/chatApi';
import formatText from '../../../utils/textFormater';
import styles from './text-response.module.css';

export const TextResponse = ({
  response,
  responseType,
  idSession,
}) => {
  const { attr, meth } = useContext(ChatContext);
  const { disableButton, messageId } = attr;
  const { sendMessage } = meth;
  const [disableButtonBlock, setDisableButtonBlock] = useState([]);

  const filterData = (data) => {
    const isGPT = data.some(item => 'gpt' in item);
    const isWatson = data.some(item => 'text' in item);
    if (isGPT) {
      return data.filter(item => item !== null
        && !('text' in item)
        && (item.gpt?.trim() !== 'Lo siento.')
      );
    }
    if (isWatson) {
      return data.filter((item, index) => item !== null && !('gpt' in item) && index !== 0);
    }
    return data.filter(item => item !== null);
  };

  const filteredResponses = filterData(response);

  const redirect = async (button, url, optionButton = false) => {
    if (disableButton || disableButtonBlock.includes(button)) return;
    validateButton({ name: button });
    const matchLink = url.match(/^(https?:\/\/|www\.|interbank.pe|\/\/)+\S+/gmu);
    if (matchLink) {
      if (!matchLink[0].startsWith('http')) {
        url = `http://${url}`;
      }
      window.open((url || '').trim(), '_blank', 'noopener');
      await ChatApi.sendEvents({
        id: messageId,
        eventType: 'redirect-external',
        description: `redirect ${(url || '').trim()}`,
        option: 'message_events',
      });
    } else {
      await sendMessage({
        message: optionButton ? url : button,
        type: 'text',
        typeUser: false,
        formatQuestion: 'text',
        watsonMenu: optionButton,
        title: button,
      });
    }
  };

  const validateButton = ({ name }) => {
    if (!disableButtonBlock.includes(name)) {
      setDisableButtonBlock((prevDisableButtonBlock) => [
        ...prevDisableButtonBlock,
        name,
      ]);
    }
  };

  const noResultMessage = ['Sin resultado', 'no-included-message'];

  return (
    <div className={styles['response-container']}>
      {responseType !== 'survey'
        && filteredResponses.map((item) => {
          const {
            gpt = '',
            text = '',
            image = '',
            btnder = '',
            btnlink = '',
            message = '',
            options = [],
          } = item;
          if (text && text !== '' && !noResultMessage.includes(text)) {
            const textFormated = text?.replace(/A:|AI:|IA:|Assistant:/g, '');
            return (
              <>
                <span
                  key={`text-principal-box-${idSession}`}
                  style={{ wordBreak: 'break-word', lineHeight: '20px' }}
                  dangerouslySetInnerHTML={formatText(textFormated, true)}
                />
                {filteredResponses?.length > 1 && <Margin bottom={1.2} />}
              </>
            );
          }
          if (gpt && gpt !== '') {
            const textFormated = gpt?.replace(/A:|AI:|IA:|Assistant:/g, '');
            return (
              <>
                <span
                  key={`gpt-principal-box-${idSession}`}
                  style={{ wordBreak: 'break-word', lineHeight: '20px' }}
                  dangerouslySetInnerHTML={formatText(textFormated, true)}
                />
                {filteredResponses?.length > 1 && <Margin bottom={1.2} />}
              </>
            );
          }
          if (message && message !== '') {
            const textFormated = message?.replace(/A:/g, '');
            return (
              <span
                key={`message-principal-box-${idSession}`}
                style={{ wordBreak: 'break-word', lineHeight: '20px' }}
                dangerouslySetInnerHTML={formatText(textFormated)}
              />
            );
          }
          if (image !== '') {
            const width = new URLSearchParams(new URL(image).search)?.get(
              'width'
            );
            return (
              <div
                className={styles['image-container']}
                key={`image-principal-box-${idSession}`}
              >
                <img
                  key={`response-component-${idSession}`}
                  width={width || '132'}
                  height={'auto'}
                  src={image}
                  alt={'response ilustration'}
                />
                {filteredResponses?.length > 1 && <Margin bottom={1.6} />}
              </div>
            );
          }
          if (btnlink !== '') {
            const [initContent, button = ''] = btnlink.split('btn-link');
            const [buttonText, buttonLink] = button.split('>');
            const [buttonUrl] = buttonLink?.trim()?.split(' ');
            return (
              <>
                <Margin bottom={1.2} />
                <span
                  key={`text-principal-box-${idSession}`}
                  style={{ wordBreak: 'break-word', lineHeight: '20px' }}
                  dangerouslySetInnerHTML={formatText(initContent, true)}
                />
                <div
                  key={`btnlink-principal-box-${idSession}`}
                  className={styles['buttons-container']}
                >
                  <Button
                    color={'green'}
                    disabled={disableButton || disableButtonBlock.includes(buttonText)}
                    onClick={() => redirect(buttonText, buttonUrl)}
                  >
                    {buttonText}
                  </Button>
                </div>
              </>
            );
          }
          if (btnder !== '') {
            return (
              <>
                {(btnder || []).map((it) => {
                  const { btnName, btnUrl } = it;
                  return (
                    <>
                      <div
                        key={`btnder-principal-box-${idSession}`}
                        className={styles['buttons-container']}
                      >
                        <Margin top={0.8} />
                        <Button
                          color={'green'}
                          disabled={disableButton || disableButtonBlock.includes(btnName)}
                          onClick={() => redirect(btnName, btnUrl)}
                        >
                          {btnName}
                        </Button>
                      </div>
                    </>
                  );
                })}
              </>
            );
          }
          if (options?.length > 0) {
            return (
              <>
                <p>
                  Seleccione la opción que mas se ajuste a su consulta
                </p>
                <Margin bottom={0.8} />
                {(options || []).map((it) => {
                  const { label, value } = it;
                  const { input } = value;
                  const [buttonName] = label.split('>');
                  return (
                    <>
                      <div
                        key={`options-principal-box-${idSession}`}
                        className={styles['buttons-container']}
                      >
                        <Margin top={0.8} />
                        <Button
                          color={'green'}
                          style={{ fontSize: '14px' }}
                          disabled={disableButton || disableButtonBlock.includes(buttonName)}
                          onClick={() => redirect(buttonName, input.text, true)}
                        >
                          {buttonName}
                        </Button>
                      </div>
                    </>
                  );
                })}
              </>
            );
          }
          return <></>;
        })}
    </div>
  );
};

TextResponse.propTypes = {
  response: PropTypes.array,
  responseType: PropTypes.string,
  idSession: PropTypes.string,
};
