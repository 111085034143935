/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ChatProvider from '../context/chat-context';
import { PopupProvider } from '../context/popup-context';
import { MessagesProvider } from '../context/message-context';
import { Chat } from '../screens/Chat';
import { Home } from '../screens/Home';
import { Login } from '../screens/Login';
import { Typography } from '@rmwc/typography';
import { v4 as uuidv4 } from 'uuid';
import packageJson from '../../package.json';
import '@rmwc/card/styles';
import '@rmwc/typography/styles';
import '../index.css';

const {
  REACT_APP_ALLY,
  REACT_APP_CAMPAIGN,
  REACT_APP_DIALOG_BOTSMART,
} = process.env;

export const setUp = {
  ally: REACT_APP_ALLY,
  botName: 'daviplataPrototipo',
  config: {
    provider: 'watson',
    dialogId: REACT_APP_DIALOG_BOTSMART,
  },
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiand0LWFjY2VzcyIsImlhdCI6MTY2MDMyMTQ1NywiZXhwIjoxNjYwMzI1MDU3fQ.rjTah4HbrzuPdJy3ncjxW1eMt7Pxj6_r7wT2BuH8-Pk',
  infConversation: {
    clientId: uuidv4(),
    clientCampaignId: REACT_APP_CAMPAIGN,
    id: uuidv4(),
    conversation: [],
    integrationId: 'hE5T9OjxTNfpPYsICkUi',
    campaignId: REACT_APP_CAMPAIGN,
    channel: 'website',
  },
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={'/'}
        element={<Login/>}
      />
      <Route
        path={'/home'}
        element={<Home/>}
      />
      <Route
        path={'/chat'}
        element={
          <ChatProvider {...setUp}>
            <Chat {...setUp} />
          </ChatProvider>
        }
      />
    </Routes>
  );
};

export const RouterApp = () => {
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const URL = window.location.href;
    if (URL.includes('http://localhost:3000/')
      || URL.includes('https://dev.interbank-assistant.bpsmart.ai/')
    ) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, []);

  useEffect(() => {
    const version = localStorage.getItem('version');
    if (version !== packageJson.version) {
      if (version && 'caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.location.reload(true);
      }
      localStorage.removeItem('version');
      localStorage.setItem('version', packageJson.version);
    }
  }, []);

  return (
    <BrowserRouter>
      <MessagesProvider>
        <PopupProvider>
          <AppRoutes />
          {flag && (
            <div className={'float-version'}>
              <Typography use={'caption'}>
                <strong>
                  v.{localStorage.getItem('version')}
                </strong>
              </Typography>
            </div>
          )}
        </PopupProvider>
      </MessagesProvider>
    </BrowserRouter>
  );
};
