import React from 'react';
import { useNavigate } from 'react-router-dom';
import AvatarIcon from '../../assets/icons/mdi-catalina-avatar.svg';
import BackIcon from '../../assets/icons/mdi-back-white.svg';
import CloseIcon from '../../assets/icons/mdi-close-white.svg';
import MinimizeIcon from '../../assets/icons/mdi-minimize-white.svg';
import VerifiedIcon from '../../assets/icons/mdi-oficial.svg';
import styles from './navbar.module.css';

export const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.navbar}>
      <div className={styles.back} onClick={() => navigate('/home')}>
        <img src={BackIcon} alt={'back'} className={styles.icons} />
      </div>
      <div className={styles['info-container']}>
        <img src={AvatarIcon} alt={'back'} className={styles.avatar} />
        <div>
          <div className={styles.info}>
            <strong>Catalina</strong>
            <img src={VerifiedIcon} alt={'back'} className={styles.verified} />
          </div>
          <p className={styles.subtitle}>Agente virtual</p>
        </div>
      </div>
      <div className={styles.actions}>
        <img src={MinimizeIcon} alt={'minimize'} className={styles.icons} />
        <img src={CloseIcon} alt={'close'} className={styles.icons} />
      </div>
    </div>
  );
};
